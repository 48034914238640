import { Injectable } from '@angular/core'
import { map } from 'rxjs/operators'
import { HttpClient } from '@angular/common/http'
import { environment } from 'src/environments/environment'
@Injectable({
  providedIn: 'root',
})
export class WpIonicService {
  endpoint = environment.endpoint

  constructor(private httpClient: HttpClient) {}

  getCategoryDetail(categoryId: number) {
    const options = {
      observe: 'response' as 'body',
    }

    return this.httpClient
      .get<any>(`${this.endpoint}/categories/${categoryId}`, options)
      .pipe(
        map(res => {
          return res['body']
        }),
      )
  }

  getPostFromCategory(categoryId: number, page = 1, perPage = 1) {
    console.log('getPostFromCategory', categoryId, page, perPage)
    const options = {
      observe: 'response' as 'body',
      params: {
        per_page: perPage,
        page,
      },
    }

    return this.httpClient
      .get<any[]>(
        `${this.endpoint}/posts?categories=${categoryId}&_embed`,
        options,
      )
      .pipe(
        map(res => ({
          posts: res['body'],
          pages: Number(res['headers'].get('x-wp-totalpages')),
          length: Number(res['headers'].get('x-wp-total')),
          perPage,
          page,
        })),
      )
  }

  postDetails(id) {
    return this.httpClient.get(`${this.endpoint}/posts/${id}?_embed`)
  }

  postDetailsFromSlug(slug) {
    return this.httpClient.get(`${this.endpoint}/posts?_embed&slug=${slug}`)
  }
}
