import { Injectable } from '@angular/core'
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http'
import { Observable, throwError, EMPTY } from 'rxjs'
import { catchError, timeout } from 'rxjs/operators'

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      timeout(10000),
      catchError(res => {
        switch (res.status) {
          case 400 && request.url.includes('posts?'):
            return []
          case 404:
            return EMPTY
        }
        return throwError(res)
      }),
    )
  }
}
