import { Injectable } from '@angular/core'
import {
  ActivatedRouteSnapshot,
  Resolve,
  Router,
  RouterStateSnapshot,
} from '@angular/router'
import { Observable } from 'rxjs'
import { CategoriesService } from '../services/categories.service'
import { tap } from 'rxjs/operators'

@Injectable({ providedIn: 'root' })
export class PostResolver implements Resolve<any> {
  constructor(private service: CategoriesService, private router: Router) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<any> | Promise<any> | any {
    return this.service.postDetailsFromSlug(route.paramMap.get('slug')).pipe(
      tap(data => {
        if (!data['length']) {
          this.router.navigateByUrl('/')
        }
      }),
    )
  }
}
