function parseGeneralDate(str: string): Date {
  try {
    const date = str.split('/').map(item => Number(item))
    return new Date(date[2], date[1] - 1, date[0], 23, 59, 59)
  } catch (exceptionVar) {
    return
  }
}

export { parseGeneralDate }
