import { NgModule } from '@angular/core'
import { PreloadAllModules, RouterModule, Routes } from '@angular/router'
import { PostResolver } from './shared/resolvers/post.resolver'

const routes: Routes = [
  {
    path: 'kalender',
    redirectTo: 'categories/7',
  },
  {
    path: '',
    loadChildren: () =>
      import('./categories/categories.module').then(
        m => m.CategoriesPageModule,
      ),
  },
  {
    path: 'categories/:id',
    loadChildren: () =>
      import('./category-detail/category-detail.module').then(
        m => m.CategoryDetailPageModule,
      ),
  },
  {
    path: ':slug',
    loadChildren: () =>
      import('./category-detail/category-detail.module').then(
        m => m.CategoryDetailPageModule,
      ),
    resolve: {
      post: PostResolver,
    },
  },
  {
    path: '**',
    redirectTo: '',
  },
]

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
