import { Injectable } from '@angular/core'
import { BehaviorSubject, EMPTY, forkJoin, Observable, throwError } from 'rxjs'
import { map } from 'rxjs/operators'
import { LoadingService } from './loading.service'
import { WpIonicService } from './wp-ionic.service'
import { parseGeneralDate } from '../helpers/date'
import { DomSanitizer } from '@angular/platform-browser'
export interface Category {
  name?: string
  slug?: string
  extension: string
  extension0?: string
  id: number
  posts?: any[]
  fetchedPages: number
  length?: number
  newsSlider?: boolean
  hideExpiredPost?: boolean
  isFeatured?: boolean
}
@Injectable({
  providedIn: 'root',
})
export class CategoriesService {
  initialized = new BehaviorSubject(false)
  perPage = 50

  categories: Category[] = [
    {
      id: 51,
      extension: 'png',
      posts: [],
      fetchedPages: 0,
      isFeatured: true,
    },
    {
      id: 4,
      extension: 'png',
      posts: [],
      fetchedPages: 0,
      newsSlider: true,
    },
    {
      id: 7,
      extension: 'png',
      posts: [],
      fetchedPages: 0,
      newsSlider: true,
      hideExpiredPost: true,
    },
    {
      id: 9,
      extension: 'mp4',
      posts: [],
      fetchedPages: 0,
    },
    {
      id: 6,
      extension: 'mp4',
      extension0: 'png',
      posts: [],
      fetchedPages: 0,
    },
    {
      id: 5,
      extension: 'png',
      posts: [],
      fetchedPages: 0,
      newsSlider: true,
    },
    {
      id: 8,
      extension: 'png',
      posts: [],
      fetchedPages: 0,
    },
  ]

  directCatId: number
  directPosts = []

  isViewingDetail = false

  constructor(
    private wpService: WpIonicService,
    private loadingSv: LoadingService,
    private sanitizer: DomSanitizer,
  ) {
    this.loadingSv.isLoading = true

    let pending = this.categories.length
    this.categories.forEach(category => {
      const perPage = category.hideExpiredPost
        ? 50
        : category.newsSlider
        ? 4
        : 1

      forkJoin({
        detail: this.wpService.getCategoryDetail(category.id),
        posts: this.processResults(
          this.wpService.getPostFromCategory(category.id, 1, perPage),
          category,
        ),
      }).subscribe(({ detail, posts }: any) => {
        category.name = detail.name
        category.slug = detail.slug

        if (posts) {
          category.posts = posts.posts
          category.length = posts.length
        }

        pending--

        if (!pending) {
          this.loadingSv.isLoading = false
          this.initialized.next(true)
        }
      })
    })
  }

  processPost(post) {
    post.acf.end_date = parseGeneralDate(post.acf.end_date)
    post.acf.event_date = parseGeneralDate(post.acf.event_date)
    post.content.rendered = this.sanitizer.bypassSecurityTrustHtml(
      post.content.rendered,
    )
  }

  processResults(request: Observable<any>, category: Category) {
    return request.pipe(
      map(({ posts, length, page, perPage }) => {
        if (page <= category.fetchedPages) {
          return false
        }
  
        if (posts[0] && perPage === this.perPage) {
          category.fetchedPages++
        }
  
        category.length = length
  
        const today = new Date().getTime()
  
        posts = posts.filter(post => {
          this.processPost(post)
  
          let include = true
          if (this.directCatId && this.directCatId === category.id) {
            include = include && post.id !== this.directPosts[0].id
          }
  
          if (category.hideExpiredPost) {
            const dateToCheck = post.acf.end_date || post.acf.event_date
            include = include && dateToCheck.getTime() > today
          }
          return include
        })
  
        // Sort posts by event date
        posts = posts.sort((a, b) => {
          const dateA = a.acf.event_date ? new Date(a.acf.event_date).getTime() : 0
          const dateB = b.acf.event_date ? new Date(b.acf.event_date).getTime() : 0
          return dateA - dateB
        })
  
        if (this.directCatId && this.directCatId === category.id) {
          this.processPost(this.directPosts[0])
          posts = this.directPosts.concat(posts)
        }
  
        if (category.hideExpiredPost) {
          posts = posts.sort(
            (a, b) => a.acf.event_date.getTime() - b.acf.event_date.getTime(),
          )
        }
  
        return {
          length,
          posts,
        }
      }),
    )
  }

  fetch(id: number, page = 1) {
    const cat = this.categories.find(category => category.id === id)

    // No more to fetch
    if (
      page > 1 &&
      cat?.posts &&
      (cat.posts.length === cat.length || cat.length <= this.perPage)
    ) {
      return throwError('end')
    }

    return this.processResults(
      this.wpService.getPostFromCategory(id, page, this.perPage),
      cat,
    ).pipe(
      map(res => {
        if (res) {
          if (page == 1) {
            cat.posts = []
          }

          cat.posts.push(...res['posts'])
        }
      }),
    )
  }

  postDetailsFromSlug(slug) {
    return this.wpService.postDetailsFromSlug(slug)
  }
}
